<template>
  <div class="grade-detail">
    <div class="audios">
      <div class="audio-title">
        <p>课程列表</p>
      </div>
      <div class="book-content">
        <!--eslint-disable-next-line-->
        <div v-for="(item,index) in this.gradeBooks" class="audio-item" @click = "clickBook(item,index)">
          <div class="audio-prefix">
          <img :src="require('@/assets/bookcover/bookcover.jpg')" width="96px" height="54px">
          <p>{{ item.bookName }}</p>
            </div>
          <!--          eslint-disable-next-line-->
          <img :src="require('../assets/play.png')" class="playIcon"/>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
import {gradeList} from '/src/data/data2.js'
// import Aplayer from 'vue-aplayer'

export default {
  name: "GradeBooks",
  data(){
    return{
      grade:gradeList[0],
      bookIndex:0,
      audioLink: null,
      play:{
        src:" ",
        pic:" ",
        title: " ",
        artist:" "
      }
      // playButtonImg:require("@/assets/play.png")
    }
  },
  components:{
    // Aplayer
  },
  computed: {
    gradeBooks: function () {
      return gradeList[this.gradeIndex].gradeBooks;
    },
    bookItem: function () {
      let index = parseInt(this.bookIndex);
      let grade = this.grade;
      return grade.gradeBooks[index];
    }
  },
  methods: {
    clickBook(book,index){
      this.$router.push({name: 'BookVideo', query: {bookIndex : index, gradeIndex : this.$route.query.gradeIndex}})
    }
  },
  created() {
    console.log("创建页面成功，即将打印年级索引")
    this.gradeIndex = this.$route.query.gradeIndex;
    console.log(this.gradeIndex)
    console.log("即将打印年级书籍")
    console.log(this.gradeBooks)
    console.log("即将打印当前书籍")
    console.log(this.bookItem)
  }
}
</script>

<style scoped>
.cover-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.book-img {
  width: 150px;
  height: 150px;
  border-radius: 3px;
  margin: 5px;
}

.book-title {
  font-weight: bold;
}

.grade-detail {
  background: #f2f2f2;
  padding: 10px;
}

.audios {
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  align-items: flex-start;
}

.audio-title {
  font-weight: 700;
  display: flex;
  border-bottom: .05rem solid #ddd;
  padding: 0 3px;
  font-size: 23px;
  align-items: center;
}
.book-content{
  /*border-bottom: .05rem solid #eee;*/
  width: 100%;
}
.book-content div{
  margin-bottom: 25px;
}
.audio-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.player {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 100px;
}
.playIcon{
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
.audio-item p{
  padding-left: 10px;
}
.audio-prefix {
  display: flex;

}
</style>
